import { formatCurrency, parseCurrency } from './numbers'

const parsePeriod = ({ price, ...period }) => ({
  ...period,
  price: parseCurrency(price)
})

const parsePeriodSelectOptions = (periods) =>
  [...periods]
    .filter((period) => period.status === 'active') // 👈 Filtra apenas os ativos
    .sort((a, b) => a.time - b.time)
    .map(({ id, price, time }) => ({
      code: id,
      label: `${time} minutos - ${formatCurrency(price)}`
    }))

const parsePeriodRadioGroupOptions = (periods) =>
  [...periods]
    .sort((a, b) => a.time - b.time)
    .map(({ id, price, time }) => ({
      value: id,
      text: `${time} minutos - ${formatCurrency(price)}`
    }))

export { parsePeriod, parsePeriodSelectOptions, parsePeriodRadioGroupOptions }
